import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/psoriazal-plus-sarsaparila-i-psoriazis-header.jpg"
import LuteinComplexTablet from "./../../images/psoriazal-plus-sarsaparila-i-psoriazis-header.jpg"
import LuteinComplexDesktop from "./../../images/psoriazal-plus-sarsaparila-i-psoriazis-header.jpg"
import LuteinComplexXL from "./../../images/psoriazal-plus-sarsaparila-i-psoriazis-header.jpg"

const PsoriazalSarsa = () => (
  <Layout backButton={true} pageInfo={{ pageName: "psoriazal-sarsa" }}>
    <Seo title="Сарсапарилата и псориазиса" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>САРСАПАРИЛАТА И ПСОРИАЗИСА</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Сарсапарилата и псориазиса"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Сарсапарилата и псориазиса
          </h1>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={12}>
          <p>
            Основната съставка на Псориазал Плюс е корен от сарсапарила. Това е
            билка, използвана от векове за лечение на кожата и пречистване на
            кръвта.
          </p>
          <p>
            Медицински проучвания потвърждават традиционната употреба на
            сарсапарила за кожни проблеми като псориазис, екзема и акне. В New
            England Journal of Medicine е публикувано, че сарсапарилата
            драстично подобрява състоянието на псориазиса. Там е описано
            клинично проучване, проведено с 92 пациента. Резултатите показват,
            че симптомите намаляват при 62% от случаите и напълно изчезват при
            18% от тях. Една от възможните причини за ефекта върху псориазиса е
            способността на сарсапарилата да пречиства кръвта. Доказано е, че
            хора с псориазис имат високи нива на ендотоксини, циркулиращи в
            кръвта (ендотоксините са структурни фрагменти от нормални чревни
            бактерии). Сарсапонинът – един от главните стероиди, съдържащи се в
            сарсапарилата, се свързва с ендотоксините и ги премахва, и по този
            начин облекчава симптомите.
          </p>
          <p>
            Свързването с ендотоксините е най-вероятната причина за използване
            на корена от сарсапарила в продължение на векове за пречистване на
            кръвта.
          </p>
          <p>
            Други здравословни проблеми, свързани с високи нива на ендотоксини в
            кръвта включват екзема, артрит и улцерозен колит.
          </p>
          <p>
            Коренът от сарсапарила действа тонизиращо на тялото, премахва
            токсините, както и пречиства лимфните възли.
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <p>Можете да откриете ПСОРИАЗАЛ ПЛЮС в най-близката до вас аптека.</p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/psoriazal-plus">
              Повече информация за ПСОРИАЗАЛ ПЛЮС можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default PsoriazalSarsa
